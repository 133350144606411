const TOKEN = process.env.VUE_APP_BITLY;

const bitly = {
  async shorten(link) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await fetch("https://api-ssl.bitly.com/v4/shorten", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          long_url: link
        })
      });
      return response.json();
    } catch (error) {
      throw error;
    }
  }
};

export default bitly;
