import Bitly from "./bitly";

const link = {
  async tracking(
    serviceId,
    isPackage = false,
    isParcel = false,
    shortenUrl = false
  ) {
    let bitlyResponse = null;
    let trackingUrl = "";
    if (isPackage) {
      trackingUrl = `${process.env.VUE_APP_WEB_APP_URL}/tracking/package/${serviceId}`;
    } else if (isParcel) {
      trackingUrl = `${process.env.VUE_APP_WEB_APP_URL}/tracking/parcel/${serviceId}`;
    } else {
      trackingUrl = `${process.env.VUE_APP_WEB_APP_URL}/tracking/service/${serviceId}`;
    }

    // If in the implementation you want a short link,
    // the prop shorten URL implements the module bitly to shorten the tracking URL.
    // This help to made test in the component
    if (shortenUrl) {
      bitlyResponse = await Bitly.shorten(trackingUrl).catch(() => {
        return null;
      });
    }
    if (typeof bitlyResponse?.link === "string") {
      return bitlyResponse.link;
    } else {
      return trackingUrl;
    }
  }
};

export default link;
