<template>
  <div
    class="container-shared-service mx-1 rounded"
    :class="{ 'variant-button': isButton, 'variant-link': !isButton }"
  >
    <div>
      <b-popover
        v-if="error"
        boundary
        :target="`tracking-btn-${id}`"
        :show.sync="success"
        triggers="click"
        :placement="tooltipPlacement"
      >
        Haz otro clic para copiar
      </b-popover>
      <b-popover
        v-else
        boundary
        :target="`tracking-btn-${id}`"
        :show.sync="success"
        triggers="click"
        :placement="tooltipPlacement"
      >
        Link copiado
      </b-popover>
    </div>

    <z-button
      v-if="variant === 'button'"
      :id="`tracking-btn-${id}`"
      data-test-id="share-service-btn"
      :loading="loading"
      @click="getTrackingUrl"
    >
      {{ buttonText }}
    </z-button>

    <div
      v-else
      :id="`tracking-btn-${id}`"
      v-tooltip="'Compartir viaje'"
      data-test-id="share-service-btn"
      class="share-service"
      role="button"
      @click.stop="getTrackingUrl"
    >
      <z-icon name="Link" />
    </div>
  </div>
</template>

<script>
import Clipboard from "../utils/clipboard";
import Link from "../utils/link";
import ZIcon from "./ZIcon";

export default {
  name: "ZTrackingLink",

  components: {
    ZIcon
  },

  props: {
    id: {
      type: String,
      required: true
    },
    isPackage: {
      type: Boolean,
      default: false
    },
    isParcel: {
      type: Boolean,
      default: false
    },
    shortenUrl: {
      type: Boolean,
      default: false
    },
    tooltipPlacement: {
      type: String,
      default: "left",
      validator: val =>
        [
          "topright",
          "top",
          "topleft",
          "bottomright",
          "bottom",
          "bottomleft",
          "righttop",
          "right",
          "lefttop",
          "rightbottom",
          "left",
          "leftbottom"
        ].indexOf(val) > -1
    },
    variant: {
      type: String,
      default: "link",
      validator: val => ["link", "button"].indexOf(val) > -1
    },
    buttonText: {
      type: String,
      default: "Compartir"
    }
  },

  data() {
    return {
      loading: false,
      success: false,
      trackingUrl: "",
      error: false
    };
  },

  watch: {
    success(val) {
      if (val) {
        setTimeout(() => {
          this.success = false;
        }, 2000);
      }
    }
  },

  computed: {
    isButton() {
      return this.variant === "button";
    }
  },

  methods: {
    async getTrackingUrl() {
      try {
        if (!this.trackingUrl) {
          this.loading = true;
          this.trackingUrl = await Link.tracking(
            this.id,
            this.isPackage,
            this.isParcel,
            this.shortenUrl
          );
          this.loading = false;
        } else {
          this.error = false;
        }
        await this.copyToClipboard();
        this.$emit("copy", this.trackingUrl);
      } catch {
        this.error = true;
      }
    },
    async copyToClipboard() {
      try {
        await Clipboard.copy(this.trackingUrl);
        this.success = true;
        this.$emit("copy-success");
      } catch {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-shared-service {
  &.variant-link {
    width: 32px;
    height: 32px;
    padding: 0.25rem;
  }

  .share-service {
    color: $dim-gray;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: rgba($royal-blue, 0.06);
  }
}
</style>
